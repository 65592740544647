<template>
  <div :class="['right-panel', `right-panel-${prizeType}`]">
    <!-- 区分动态和非动态 -->
    <div v-if="prizeType === 'avatar'">
      <el-scrollbar class="scroll-bar-avatar" ref="scrollBar">
          <div class="luck-userList">
            <div class="luck-user" v-for="({id, user}) in luckyUser" :key="id">
              <UserAvatar :isWear="false" :isPreview="true" :NopreImage="false"  :user="user"></UserAvatar>
              <span>{{ user.name }}</span>
            </div>
          </div>
      </el-scrollbar>
    </div>
    <div v-else class="right-container">
      <template v-if="luckyUser.length">
        <div class="right-head">
          <div :class="['scroll-header',  luckyUser.length < 5 && 'jusctify-center']" :style="{transform: `translateX(${transformLeft}px)`}">
              <div class="luck-user" v-for="({id, user}, index) in luckyUser" :key="id">
                <UserAvatar
                  :class="selectIndex !== index && 'un-slect-avatar'"
                  v-bind="{
                    isWear: false,
                    needBorder: selectIndex === index,
                    isPreview: true,
                    NopreImage: false,
                    user: user
                  }"
                  @click="selectIndex = index"
                ></UserAvatar>
                <span>{{ user.name }}</span>
              </div>
            </div>
        </div>
        <div class="blogs-bg">
          <el-scrollbar class="scroll-bar-blogs">
            <DynamicContent :dynamicData="luckyUser[selectIndex].feed_info"></DynamicContent>
          </el-scrollbar>
        </div>
      </template>
      <template v-if="luckyUser.length > 5">
        <div class="change-prize-left cursor-pointer" @click="handleTransform('left')"></div>
        <div class="change-prize-right cursor-pointer" @click="handleTransform('right')"></div>
      </template>
    </div>

  </div>
</template>

<script>
import { toRefs, reactive, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import DynamicContent from '../components/DynamicContent.vue'
export default {
  name: 'RightPanelCard',
  components: {
    DynamicContent
  },
  props: {
    prizeType: { // 抽奖类型 blog / avatar
      type: String
    }
  },
  setup (props) {
    const store = useStore()
    const scrollBar = ref(null)
    const state = reactive({
      prizeType: computed(() => props.prizeType), // 奖项类别
      luckyUser: computed(() => store.getters['prize/luckyUser']), // 中奖名单
      transformLeft: 0,
      selectIndex: 0
    })

    const methods = {
      handleTransform (direct) { // 左右切换index
        const width = document.querySelector('.luck-user').clientWidth
        if (direct === 'right') {
          if (state.selectIndex === state.luckyUser.length - 1) return
          state.transformLeft -= width
          state.selectIndex += 1
        } else {
          if (!state.selectIndex) return
          state.selectIndex -= 1
          if (state.transformLeft === 0) return
          state.transformLeft += width
        }
      }
    }

    // 数据变化 初始化select 和 平移位置
    watch(() => state.blogsData, () => {
      state.transformLeft = 0
      state.selectIndex = 0
    })

    return {
      ...toRefs(state),
      ...methods,
      scrollBar
    }
  }
}
</script>

<style lang="less" scoped>
// 头像样式
  .right-panel {
    padding: 59px 132px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .right-panel-blog {
    padding: 59px 60px;
    .right-head {
      height: 140px;
      margin: 0 70px;
      overflow: hidden;
      position: relative;
      width: 100%;
    }
  }
  .right-panel-avatar {
    padding-right: 5px;
    .luck-userList {
      padding-right: 127px;
    }
  }
  .scroll-bar-avatar {
    height: 500px !important;
    :deep(.el-scrollbar__view) {
      width: 100%;
      height: 100%;
    }
    :deep(.el-scrollbar__wrap) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .luck-userList {
      justify-content: center;
      height: 100%;
    }
  }
  .luck-userList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

:deep(.luck-user) {
  min-width: 20% !important;
  height: 140px;
  margin-top: 40px;
  &:nth-child(-n +5) {
    margin-top: 0;
  }
  .user-avatar {
    margin-right: 0;
  }
  span {
    display: block;
    text-align: center;
    margin-top: 12px;
    font-size: 16px;
    color: #E8E8E8;
  }
  p, .el-image {
    width: 90px !important;
    height: 90px !important;
    line-height: 90px !important;
  }
}

// 微博样式
.right-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  // 头部滑动区域
  .scroll-header {
    position: absolute;
    transition: all .3s;
    width: 100%;
    height: 140px;
    top: 0;
    left: 0;
    display: flex;
    & .luck-user {
      min-width: 20%;
      margin-top: 0;
    }
    .user-avatar {
      margin-right: 0;
    }
  }
  .jusctify-center {
    justify-content: center;
  }
  .blogs-bg {
    position: absolute;
    top: 150px;
    width: 100%;
    height: 420px !important;
    padding: 10px;
    background: url(../../../../assets/img/prize/dynamic-bg.png) no-repeat center/100% 100%;
    backdrop-filter: blur(20px);
    border-radius: 20px;
    z-index: 20;
  }
  .scroll-bar-blogs {
    background: #ffffff;
    border-radius: 15px;
  }
}
.change-prize-left,
.change-prize-right {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 110px;
}
.change-prize-left {
  left: 68px;
}
.change-prize-right {
  right: 68px;
}
.change-prize-left {
  background: url('../../../../assets/img/prize/add-left-normal.svg');
  background-size: 100% 100%;
  &:hover {
    background: url('../../../../assets/img/prize/add-left-hover.svg');
    background-size: 100% 100%;
  }
}
.change-prize-right {
  background: url('../../../../assets/img/prize/add-right-normal.svg');
  background-size: 100% 100%;
  &:hover {
    background: url('../../../../assets/img/prize/add-right-hover.svg');
    background-size: 100% 100%;
  }
}
.un-slect-avatar {
  opacity: .4;
}
</style>
